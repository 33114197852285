import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../store/api';
import CenteredLoader from '../../../components/CenteredLoader';
import { Button } from 'react-bootstrap';

const ProposalFileView = ({ searchOptions, setSearchOptions, confirmDeleteModal, setConfirmDeleteModal }) => {
  const { proposalId, fileId, fileName } = useParams();
  const [downloading, setDownloading] = useState(true); 
  const [blob, setBlob] = useState(null); 

  const downloadFile = async () => {
    try {
      // create file link in browser's memory
      const { data } = await axios.get(`/proposals/${proposalId}/files/${fileId}`, { responseType: 'blob' });
      const href = URL.createObjectURL(data);
      setBlob(href);
    } catch(e) {
      window.close();
    }
    setDownloading(false);
  }

  useEffect(() => {
    downloadFile();
    return () => {
        if (blob) {
            URL.revokeObjectURL(blob);
        }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (downloading) {
    return <CenteredLoader />
  }

  if (blob == null) {
    return 'Não foi possível exibir o arquivo';
  }

  const fileExt = fileName.split('.').pop().toLowerCase(); 
  let content = '';
  if (['mp4','webm','ogv','avi','mkv','mov','flv','wmv'].includes(fileExt)) {
    content = (
      <video controls width="100%" height="100%">
        <source src={blob} type="video/mp4" />
        Seu navegador não suporta vídeos
      </video>
    )
  } else {
    content = <img src={blob} alt={fileName} width="100%" height="100%" />;
  }
  

  return <>
    <div className='d-flex justify-content-between align-items-center p-2 bg-light'>
      <span className='text-primary'>{fileName}</span>
      <Button
        size="sm"
        onClick={() => {
          const link = document.createElement('a');
          link.href = blob;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }}
      >
        Baixar
      </Button>
    </div>
    <div className='bg-white'>
      {content}
    </div>
  </>;
};

export default ProposalFileView;
